@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 64px 0;

  @media screen and (min-width: breakpoints.$media_l) {
    margin-left: -34px;
  }
}

.title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  margin: 0;
  color: colors.$text_dark_primary;
}

.detail {
  margin-top: 8px;
  width: 22px;
  height: 22px;

  path {
    fill: colors.$blue;
  }
}
