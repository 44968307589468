@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  height: 80px;
  padding-top: 12px;
  @include base.row(space-between);
  @include base.padding;

  @media screen and (min-width: breakpoints.$media_l) {
    padding-top: 48px;
  }
}

.logo {
  width: 200px;
  filter: invert(1);
}

.itemsContainer {
  position: relative;
  @include base.row(flex-start, 24px);

  @media screen and (min-width: breakpoints.$media_l) {
    @include base.row(flex-start, 48px);
  }
}

.items {
  display: none;

  @media screen and (min-width: breakpoints.$media_m) {
    @include base.row(flex-start, 24px);
  }

  @media screen and (min-width: breakpoints.$media_l) {
    @include base.row(flex-start, 48px);
  }
}

.menuItem {
  color: colors.$white;
  font-weight: 700;
  font-size: 14px;
  transition: 0.2s ease;

  &:hover {
    color: colors.$blue;
    transform: translateY(-3px);
  }

  @media screen and (min-width: breakpoints.$media_m) {
    font-size: 16px;
  }
}

.button {
  @extend .button;
  display: none;

  @media screen and (min-width: breakpoints.$media_m) {
    display: block;
  }
}
