@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  @include base.padding;
  padding-bottom: 60px;
  background-color: colors.$background_primary;
}

.titleContainer {
  @media screen and (min-width: breakpoints.$media_m) {
    padding-bottom: 20px;
  }
}

.title {
  color: colors.$white;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media screen and (min-width: breakpoints.$media_l) {
    gap: 24px;
  }
}

.subtitle {
  color: colors.$white;
  font-size: 18px;
}

.text {
  font-weight: 500;
  color: colors.$white;
  line-height: 180%;
  font-size: 16px;
}

.logo {
  transform: scale(0.6);
  transform-origin: left top;
  margin-bottom: -30px;
  filter: invert(1);
}
