@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/variables";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  @include base.padding;
  padding-right: 0 !important;
  overflow-x: hidden;
  padding-bottom: 50px;


  @media screen and (min-width: breakpoints.$media_l) {
    min-height: auto;
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: variables.$content_padding_media_l !important;
    gap: 48px;
  }

  @media screen and (min-width: breakpoints.$media_xl) {
    padding-right: variables.$content_padding_media_xl;
  }
}

.content {
  @include base.padding;
  padding-left: 0 !important;
}

.text {
  white-space: pre-wrap;
  color: colors.$text_dark_secondary;
  line-height: 180%;
  font-size: 16px;
}

.details {
  width: 100%;
  height: 100%;
  @include base.padding;
  padding-left: 0 !important;
  position: relative;
}

.icon {
  @media screen and (min-width: breakpoints.$media_l) {
    margin-top: 84px;
    transform: scale(1.4);
    transform-origin: right bottom;
  }
}

.woodImage {
  width: 300px;
  position: absolute;
  bottom: 0;
  left: 230px;
  transform: translateX(-50%);
  object-fit: contain;

  @media screen and (min-width: breakpoints.$media_l) {
    width: 400px;
    top: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
  }
}
