@use "colors";
@use "variables";
@use "breakpoints";

@mixin max-length($length: 200px) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $length;
}

@mixin max-lines($lines: 5) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin row($justify-content: flex-start, $gap: 12px) {
  display: flex;
  flex-direction: row;
  justify-content: $justify-content;
  align-items: center;
  gap: $gap;
}

@mixin column($justify-content: flex-start, $align-items: center, $gap: 12px) {
  display: flex;
  flex-direction: column;
  justify-content: $justify-content;
  align-items: $align-items;
  gap: $gap;
}

@mixin padding {
  padding-left: variables.$content_padding_media_s;
  padding-right: variables.$content_padding_media_s;

  @media screen and (min-width: breakpoints.$media_m) {
    padding-left: variables.$content_padding_media_m;
    padding-right: variables.$content_padding_media_m;
  }

  @media screen and (min-width: breakpoints.$media_l) {
    padding-left: variables.$content_padding_media_l;
    padding-right: variables.$content_padding_media_l;
  }

  @media screen and (min-width: breakpoints.$media_xl) {
    padding-left: variables.$content_padding_media_xl;
    padding-right: variables.$content_padding_media_xl;
  }
}

.button {
  max-width: 190px;
  height: 48px;
  background-color: colors.$blue;
  border: none;
  font-family: "Poppins", sans-serif;
  color: colors.$white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  line-height: 23px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s ease;

  &:disabled {
    opacity: 0.7;
  }
}
